@-webkit-keyframes wave-very-light {
  0% {
    -webkit-transform: rotate(-0.5deg); }
  50% {
    -webkit-transform: rotate(0.5deg); }
  100% {
    -webkit-transform: rotate(-0.5deg); } }

@keyframes wave-very-light {
  0% {
    transform: rotate(-0.5deg); }
  50% {
    transform: rotate(0.5deg); }
  100% {
    transform: rotate(-0.5deg); } }

@-webkit-keyframes wave-light {
  0% {
    -webkit-transform: rotate(-1deg); }
  50% {
    -webkit-transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(-1deg); } }

@keyframes wave-light {
  0% {
    transform: rotate(-1deg); }
  50% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(-1deg); } }

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(-3deg); }
  50% {
    -webkit-transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(-3deg); } }

@keyframes wave {
  0% {
    transform: rotate(-3deg); }
  50% {
    transform: rotate(3deg); }
  100% {
    transform: rotate(-3deg); } }

.pink-bg {
  background-color: #D96392; }

.pink-txt {
  color: #D96392; }

.pink-bkg {
  background: #D96392;
  background-color: #D96392; }

.pink-brd {
  border-color: #D96392; }

div.button.pink {
  background: linear-gradient(to right, rgba(217, 99, 146, 0.15), rgba(255, 255, 255, 0)); }
  div.button.pink a,
  div.button.pink a:after {
    color: #D96392; }

a.pink {
  color: rgba(217, 99, 146, 0.75); }
  a.pink:hover {
    color: #D96392; }

.purple-bg {
  background-color: #9013FE; }

.purple-txt {
  color: #9013FE; }

.purple-bkg {
  background: #9013FE;
  background-color: #9013FE; }

.purple-brd {
  border-color: #9013FE; }

div.button.purple {
  background: linear-gradient(to right, rgba(144, 19, 254, 0.15), rgba(255, 255, 255, 0)); }
  div.button.purple a,
  div.button.purple a:after {
    color: #9013FE; }

a.purple {
  color: rgba(144, 19, 254, 0.75); }
  a.purple:hover {
    color: #9013FE; }

.blue-bg {
  background-color: #00ADCD; }

.blue-txt {
  color: #00ADCD; }

.blue-bkg {
  background: #00ADCD;
  background-color: #00ADCD; }

.blue-brd {
  border-color: #00ADCD; }

div.button.blue {
  background: linear-gradient(to right, rgba(0, 173, 205, 0.15), rgba(255, 255, 255, 0)); }
  div.button.blue a,
  div.button.blue a:after {
    color: #00ADCD; }

a.blue {
  color: rgba(0, 173, 205, 0.75); }
  a.blue:hover {
    color: #00ADCD; }

.sky-bg {
  background-color: #97CCF6; }

.sky-txt {
  color: #97CCF6; }

.sky-bkg {
  background: #97CCF6;
  background-color: #97CCF6; }

.sky-brd {
  border-color: #97CCF6; }

div.button.sky {
  background: linear-gradient(to right, rgba(151, 204, 246, 0.15), rgba(255, 255, 255, 0)); }
  div.button.sky a,
  div.button.sky a:after {
    color: #97CCF6; }

a.sky {
  color: rgba(151, 204, 246, 0.75); }
  a.sky:hover {
    color: #97CCF6; }

.orange-bg {
  background-color: #F76D27; }

.orange-txt {
  color: #F76D27; }

.orange-bkg {
  background: #F76D27;
  background-color: #F76D27; }

.orange-brd {
  border-color: #F76D27; }

div.button.orange {
  background: linear-gradient(to right, rgba(247, 109, 39, 0.15), rgba(255, 255, 255, 0)); }
  div.button.orange a,
  div.button.orange a:after {
    color: #F76D27; }

a.orange {
  color: rgba(247, 109, 39, 0.75); }
  a.orange:hover {
    color: #F76D27; }

.yellow-bg {
  background-color: #F1B500; }

.yellow-txt {
  color: #F1B500; }

.yellow-bkg {
  background: #F1B500;
  background-color: #F1B500; }

.yellow-brd {
  border-color: #F1B500; }

div.button.yellow {
  background: linear-gradient(to right, rgba(241, 181, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.yellow a,
  div.button.yellow a:after {
    color: #F1B500; }

a.yellow {
  color: rgba(241, 181, 0, 0.75); }
  a.yellow:hover {
    color: #F1B500; }

.green-bg {
  background-color: #6BA500; }

.green-txt {
  color: #6BA500; }

.green-bkg {
  background: #6BA500;
  background-color: #6BA500; }

.green-brd {
  border-color: #6BA500; }

div.button.green {
  background: linear-gradient(to right, rgba(107, 165, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.green a,
  div.button.green a:after {
    color: #6BA500; }

a.green {
  color: rgba(107, 165, 0, 0.75); }
  a.green:hover {
    color: #6BA500; }

.teal-bg {
  background-color: #78B8A0; }

.teal-txt {
  color: #78B8A0; }

.teal-bkg {
  background: #78B8A0;
  background-color: #78B8A0; }

.teal-brd {
  border-color: #78B8A0; }

div.button.teal {
  background: linear-gradient(to right, rgba(120, 184, 160, 0.15), rgba(255, 255, 255, 0)); }
  div.button.teal a,
  div.button.teal a:after {
    color: #78B8A0; }

a.teal {
  color: rgba(120, 184, 160, 0.75); }
  a.teal:hover {
    color: #78B8A0; }

.gray-bg {
  background-color: #444444; }

.gray-txt {
  color: #444444; }

.gray-bkg {
  background: #444444;
  background-color: #444444; }

.gray-brd {
  border-color: #444444; }

div.button.gray {
  background: linear-gradient(to right, rgba(68, 68, 68, 0.15), rgba(255, 255, 255, 0)); }
  div.button.gray a,
  div.button.gray a:after {
    color: #444444; }

a.gray {
  color: rgba(68, 68, 68, 0.75); }
  a.gray:hover {
    color: #444444; }

.light-gray-bg {
  background-color: #999999; }

.light-gray-txt {
  color: #999999; }

.light-gray-bkg {
  background: #999999;
  background-color: #999999; }

.light-gray-brd {
  border-color: #999999; }

div.button.light-gray {
  background: linear-gradient(to right, rgba(153, 153, 153, 0.15), rgba(255, 255, 255, 0)); }
  div.button.light-gray a,
  div.button.light-gray a:after {
    color: #999999; }

a.light-gray {
  color: rgba(153, 153, 153, 0.75); }
  a.light-gray:hover {
    color: #999999; }

.lightest-gray-bg {
  background-color: #fbfbfb; }

.lightest-gray-txt {
  color: #fbfbfb; }

.lightest-gray-bkg {
  background: #fbfbfb;
  background-color: #fbfbfb; }

.lightest-gray-brd {
  border-color: #fbfbfb; }

div.button.lightest-gray {
  background: linear-gradient(to right, rgba(251, 251, 251, 0.15), rgba(255, 255, 255, 0)); }
  div.button.lightest-gray a,
  div.button.lightest-gray a:after {
    color: #fbfbfb; }

a.lightest-gray {
  color: rgba(251, 251, 251, 0.75); }
  a.lightest-gray:hover {
    color: #fbfbfb; }

.white-bg {
  background-color: white; }

.white-txt {
  color: white; }

.white-bkg {
  background: white;
  background-color: white; }

.white-brd {
  border-color: white; }

div.button.white {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }
  div.button.white a,
  div.button.white a:after {
    color: white; }

a.white {
  color: rgba(255, 255, 255, 0.75); }
  a.white:hover {
    color: white; }

.black-bg {
  background-color: black; }

.black-txt {
  color: black; }

.black-bkg {
  background: black;
  background-color: black; }

.black-brd {
  border-color: black; }

div.button.black {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.black a,
  div.button.black a:after {
    color: black; }

a.black {
  color: rgba(0, 0, 0, 0.75); }
  a.black:hover {
    color: black; }

.redorange-bg {
  background-color: #EE4A00; }

.redorange-txt {
  color: #EE4A00; }

.redorange-bkg {
  background: #EE4A00;
  background-color: #EE4A00; }

.redorange-brd {
  border-color: #EE4A00; }

div.button.redorange {
  background: linear-gradient(to right, rgba(238, 74, 0, 0.15), rgba(255, 255, 255, 0)); }
  div.button.redorange a,
  div.button.redorange a:after {
    color: #EE4A00; }

a.redorange {
  color: rgba(238, 74, 0, 0.75); }
  a.redorange:hover {
    color: #EE4A00; }

.magenta-bg {
  background-color: #bd10e0; }

.magenta-txt {
  color: #bd10e0; }

.magenta-bkg {
  background: #bd10e0;
  background-color: #bd10e0; }

.magenta-brd {
  border-color: #bd10e0; }

div.button.magenta {
  background: linear-gradient(to right, rgba(189, 16, 224, 0.15), rgba(255, 255, 255, 0)); }
  div.button.magenta a,
  div.button.magenta a:after {
    color: #bd10e0; }

a.magenta {
  color: rgba(189, 16, 224, 0.75); }
  a.magenta:hover {
    color: #bd10e0; }

body {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 21px;
  line-height: 1.619;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

small {
  font-size: 15px;
  line-height: 1.62;
  display: block; }

h1 {
  font-size: 140px;
  line-height: 0.96;
  margin-bottom: 77px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }

h2 {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 20px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 80px;
      line-height: 1;
      margin-bottom: 30px; } }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 96px;
      line-height: .91;
      margin-bottom: 30px; } }

h3 {
  font-size: 64px;
  line-height: 0.984;
  margin-top: 76px;
  margin-bottom: 76px;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100; }
  h3.intro {
    margin-top: 0;
    font-size: 48px;
    line-height: 1.08; }
  h3.quote {
    font-style: normal;
    font-weight: 100;
    font-size: 25px;
    line-height: 1.2; }
    @media screen and (min-width: 768px) {
      h3.quote {
        font-size: 40px; } }

h4.sub {
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 22px;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    h4.sub {
      font-size: 36px; } }

h5 {
  font-size: 21px;
  line-height: 1.4;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  h5.uppercase-wide-spacing {
    font-weight: 700;
    font-size: 12px;
    margin: 40px 0; }
    @media screen and (min-width: 375px) {
      h5.uppercase-wide-spacing {
        font-size: 14px; } }
    @media screen and (min-width: 1024px) {
      h5.uppercase-wide-spacing {
        font-size: 21px; } }

.uppercase-wide-spacing {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .uppercase-wide-spacing {
      text-align: left;
      font-size: 21px; } }

p {
  max-width: 100%;
  margin-bottom: 19px; }
  p:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    p {
      margin-bottom: calc(21px * 1.619); } }

a {
  color: rgba(68, 68, 68, 0.6);
  text-decoration: none; }
  a:hover {
    color: #444444; }

sup {
  font-size: 60%; }

strong, b, .bold {
  font-weight: bold; }

.normal {
  font-weight: normal; }

.lighter {
  font-weight: lighter; }

.lightest {
  font-weight: 100; }

em, .italic, i:not(.fa) {
  font-style: italic; }

i.fab {
  font-style: normal !important; }

.uppercase {
  text-transform: uppercase; }

.footnote {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3.3px;
  line-height: 1.43;
  margin: 7px 0; }

.date {
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  letter-spacing: .2rem; }

.black5 {
  font-size: 18px;
  line-height: 1.619;
  margin-bottom: 0px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .4rem; }
  @media screen and (min-width: 960px) {
    .black5 {
      font-size: 21px; } }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  color: white;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100;
  font-size: 21px;
  font-style: normal;
  font-weight: 100;
  border: 3px solid white;
  border-radius: 10px;
  line-height: unset; }

.btn:hover {
  border: 3px solid transparent;
  color: black;
  background-color: white; }

.overlay {
  position: relative; }
  .overlay:before {
    content: '';
    display: block;
    opacity: .8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .overlay .content-width, .overlay .content-width-large {
    position: relative;
    z-index: 2; }
  .overlay.pink:before {
    background-color: #D96392; }
  .overlay.purple:before {
    background-color: #9013FE; }
  .overlay.blue:before {
    background-color: #00ADCD; }
  .overlay.sky:before {
    background-color: #97CCF6; }
  .overlay.orange:before {
    background-color: #F76D27; }
  .overlay.yellow:before {
    background-color: #F1B500; }
  .overlay.green:before {
    background-color: #6BA500; }
  .overlay.teal:before {
    background-color: #78B8A0; }
  .overlay.gray:before {
    background-color: #444444; }
  .overlay.light-gray:before {
    background-color: #999999; }
  .overlay.lightest-gray:before {
    background-color: #fbfbfb; }
  .overlay.white:before {
    background-color: white; }
  .overlay.black:before {
    background-color: black; }
  .overlay.redorange:before {
    background-color: #EE4A00; }
  .overlay.magenta:before {
    background-color: #bd10e0; }

.table {
  display: table;
  width: 100%;
  padding-top: 2rem;
  font-size: 4vw; }
  .table .nbsp {
    display: none; }
  @media screen and (min-width: 768px) {
    .table {
      font-size: 21px; }
      .table .nbsp {
        display: inline; } }
  .table .table-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center; }
    .table .table-row .arrow-spacer {
      width: 100%;
      background: #9013FE;
      height: 1px;
      position: relative;
      transform: translateY(-1px); }
      .table .table-row .arrow-spacer:after {
        content: '';
        background-image: url("/public/images/arrow-head-purple.svg");
        width: 11px;
        height: 11px;
        position: absolute;
        right: -3px;
        z-index: 1;
        display: block;
        background-repeat: no-repeat;
        top: -5px;
        transform: rotate(-90deg); }
    .table .table-row .cell {
      display: inline-block;
      text-transform: uppercase;
      font-family: 'Alte Haas Grotesk', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      letter-spacing: .2rem;
      font-weight: bold;
      padding-right: 10px; }
      .table .table-row .cell.right {
        padding-right: 0;
        padding-left: 14px; }

* {
  box-sizing: border-box; }

html {
  width: 100%;
  position: relative;
  overflow-x: hidden; }

body {
  margin: 0;
  display: none;
  background-color: #000; }
  body.loaded {
    display: block; }

img {
  width: 100%;
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.3s; }

img[data-src] {
  opacity: 0; }

body.scroll-locked {
  overflow: hidden;
  height: 100%; }

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

li > ul {
  margin-left: 1rem; }

body {
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100;
  font-size: 21px;
  line-height: 1.619; }

section {
  max-width: 100%;
  overflow: hidden; }

h1 {
  font-size: 40px;
  line-height: 0.96;
  margin-bottom: 35px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media screen and (min-width: 375px) {
    h1 {
      font-size: 50px; } }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 90px;
      margin-bottom: 36px; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 104px;
      margin-bottom: 36px; } }

h2 {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 20px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 80px;
      line-height: 1;
      margin-bottom: 30px; } }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 96px;
      line-height: .91;
      margin-bottom: 30px; } }

h3 {
  font-size: 32px;
  line-height: 1.1;
  margin-top: 35px;
  margin-bottom: 35px;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100; }
  @media screen and (min-width: 768px) {
    h3 {
      font-size: 64px;
      margin-top: 76px;
      margin-bottom: 76px;
      line-height: 0.984; } }
  h3.intro {
    margin-top: 0;
    font-size: 48px;
    line-height: 1.08; }

h5 {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media screen and (min-width: 768px) {
    h5 {
      line-height: 1.4;
      font-size: 21px; } }

p {
  margin-bottom: calc(21px * 1.619); }

p:last-child {
  margin-bottom: 0; }

a {
  color: rgba(68, 68, 68, 0.7);
  text-decoration: underline; }
  a.no-deco {
    text-decoration: none; }
  a:hover {
    color: #444444; }
  a.main-nav {
    text-decoration: none; }

.content-sections .header a, .pull-quote a, .footer a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline; }

.content-sections .header a:hover, .pull-quote a:hover, .footer a:hover {
  color: white; }

sup {
  font-size: 60%; }

strong, b, .bold {
  font-weight: bold; }

em, .italic, i:not(.fa) {
  font-style: italic; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.margin-bottom {
  margin-bottom: 132px; }

.margin-bottom-small {
  margin-bottom: 66px; }

.margin-top {
  margin-top: 132px; }

.margin-top-small {
  margin-top: 66px; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin {
  margin: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important; }

.padding-bottom {
  padding-bottom: 2rem !important; }
  @media screen and (min-width: 768px) {
    .padding-bottom {
      padding-bottom: 132px !important; } }

.padding-top {
  padding-top: 2rem !important; }
  @media screen and (min-width: 768px) {
    .padding-top {
      padding-top: 132px !important; } }

@media screen and (min-width: 768px) {
  .padding-right {
    padding: 0 13% 0 0; }
  .padding-right-medium {
    padding: 0 28% 0 0; }
  .padding-right-big {
    padding: 0 40% 0 0; } }

.padding-left {
  padding: 0 0 0 10%; }

.padding-left-medium {
  padding: 0 0 0 30%; }

.padding-left-big {
  padding: 0 0 0 40%; }

.padding-x {
  padding: 0 10% 0 10%; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

@media all and (max-width: 767px) {
  .no-padding-top-mobile {
    padding-top: 0 !important; } }

.no-padding {
  padding: 0 !important; }

.content-width {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 6%;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .content-width {
      padding: 0 10%; } }
  @media screen and (min-width: 960px) {
    .content-width {
      padding: 0; } }
  @media screen and (min-width: 768px) {
    .content-width {
      padding-top: 0;
      padding-bottom: 0; } }

@media all and (max-width: 767px) {
  .content-width + .content-width {
    padding-top: 0 !important; } }

@media all and (max-width: 767px) {
  .content-width > h3 {
    padding-top: 0 !important; } }

.content-width-medium, .content-width-large {
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .content-width-medium, .content-width-large {
      padding: 0 82px; } }
  @media screen and (min-width: 768px) {
    .content-width-medium, .content-width-large {
      padding-top: 0;
      padding-bottom: 0; } }
  .content-width-medium.no-max, .content-width-large.no-max {
    max-width: 100vw; }

.content-width-medium {
  max-width: 1280px; }

.content-width-large {
  max-width: 1440px; }

.content-width-full {
  position: relative;
  width: 100%;
  margin: 0 auto; }

.content-sections {
  background-color: white;
  color: black; }

.content-sections img.full-width {
  width: 100%; }

.content-sections .pull-quote {
  width: 100%;
  min-height: 400px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 10% 0;
  font-size: 40px;
  line-height: 1.083;
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media all and (max-width: 767px) {
    .content-sections .pull-quote.small-mobile {
      font-size: 1.5rem;
      line-height: 1.4; } }
  @media screen and (min-width: 768px) {
    .content-sections .pull-quote {
      margin: 132px 0; } }
  @media screen and (min-width: 960px) {
    .content-sections .pull-quote {
      min-height: auto;
      min-height: 50vh; } }
  @media screen and (min-width: 768px) {
    .content-sections .pull-quote {
      padding-top: 0;
      padding-bottom: 0; } }
  .content-sections .pull-quote.callout {
    font-size: 40px;
    line-height: 1.083; }
    @media screen and (min-width: 768px) {
      .content-sections .pull-quote.callout {
        font-size: 80px; } }
    .content-sections .pull-quote.callout h4 {
      font-family: 'Alte Haas Grotesk', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      font-weight: bold; }

.content-sections .inner-width {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }

.content-sections .header {
  width: 100%;
  min-height: 700px;
  color: white;
  padding: 160px 0 127px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 960px) {
    .content-sections .header {
      min-height: auto;
      height: 100vh; } }

.content-sections .header h3.intro {
  max-width: 1100px; }

.content-sections .content-section {
  margin-bottom: 130px; }

@media screen and (min-width: 768px) {
  .content-sections section > .content-width {
    margin-top: 82px; } }

.fade-white {
  position: relative;
  min-height: 400px; }
  @media screen and (min-width: 960px) {
    .fade-white {
      min-height: 55vh; } }
  .fade-white:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, white 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
    /* IE6-9 */ }

.recommendation h5 {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .4rem; }

.recommendation h3 {
  margin-top: 35px;
  margin-bottom: 65px; }

ul.styled-list {
  margin-bottom: 29px; }
  ul.styled-list li {
    list-style: initial;
    margin-left: 25px;
    padding: 0 0 11px;
    margin-bottom: 0; }
  ul.styled-list ul {
    margin-left: 25px; }
    ul.styled-list ul ul {
      magin-left: 25px; }

.card {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 9%;
  min-width: 45%;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Alte Haas Grotesk', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-weight: bold; }
  @media all and (max-width: 767px) {
    .card {
      width: 100%; } }
  .card p {
    text-align: center; }
    .card p span {
      display: block; }

.video-contain {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

.vimeo-container {
  box-shadow: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  flex: 0 0 100%;
  width: 100%;
  min-height: 1px;
  padding: 0; }
  .vimeo-container:after {
    content: "";
    display: block;
    padding-bottom: 56.25%; }
  .vimeo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.fw-vimeo-vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.flex {
  display: flex;
  flex: 1; }
  .flex.columns {
    flex-direction: column; }
  .flex.center-vertical .row {
    display: flex;
    align-items: center; }
  .flex .row {
    flex-basis: 100%; }

.mobile-space {
  display: inline; }

@media screen and (min-width: 768px) {
  .mobile-space {
    display: none; } }

.cursor-normal:hover {
  cursor: default; }

.mobile-hide {
  display: none; }
  @media screen and (min-width: 1024px) {
    .mobile-hide {
      display: initial; } }

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none; } }

.team-members {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around; }
  @media screen and (min-width: 768px) {
    .team-members {
      width: calc(100% + 48px); } }
  .team-members li > a {
    text-decoration: none; }
  .team-members .image-wrapper {
    max-width: 282px;
    margin: 0 auto; }
  .team-members .no-link .image:after {
    display: none;
    visibility: hidden; }
  .team-members .image {
    max-width: 350px;
    width: 100%;
    padding: 50%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    position: relative;
    max-width: 282px;
    max-height: 282px; }
    .team-members .image:after {
      content: "+";
      position: absolute;
      top: 0;
      right: 0;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white; }
  .team-members.orange .image:after {
    background-color: #F76D27; }
  .team-members li {
    width: calc(70%);
    margin-bottom: 100px;
    cursor: pointer; }
    @media screen and (min-width: 768px) {
      .team-members li {
        width: calc(50%); } }
  .team-members a {
    text-decoration: none; }
  .team-members h3 {
    font-size: 24px;
    line-height: 1.113;
    margin-bottom: 0;
    margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .team-members h3 {
        font-size: 48px; } }
  .team-members h5 {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
    margin: 5px;
    text-align: center; }

@media screen and (min-width: 768px) {
  .case-studies h3 {
    font-size: 44px; } }

.time-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .time-line .card {
    cursor: pointer; }
    .time-line .card:before {
      content: "+";
      position: absolute;
      top: -18px;
      right: -18px;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white;
      text-align: center;
      vertical-align: middle;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100; }
    .time-line .card:after {
      content: '';
      display: block;
      width: 100%;
      border: none;
      height: 3rem;
      bottom: -3rem;
      left: 0;
      padding: 0;
      font-size: 0;
      background-color: #6BA500;
      position: absolute; }
      @media screen and (min-width: 768px) {
        .time-line .card:after {
          height: 5rem;
          bottom: -5rem; } }
    .time-line .card:last-child:after {
      display: none; }

.side-by-side {
  display: flex;
  padding-bottom: 4rem;
  padding-top: 2rem;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .side-by-side > * {
      width: 45%; } }
  .side-by-side.image-text a {
    width: 100%; }
  .side-by-side .vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.spotlight {
  align-items: center;
  flex-wrap: wrap; }
  .spotlight .image {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .spotlight .image {
        width: 30%; } }
  .spotlight .text {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .spotlight .text {
        width: 60%; } }
    .spotlight .text h3 {
      font-size: 40px; }
    .spotlight .text a {
      text-decoration: underline; }

.image-text .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .image-text .image {
      width: 45%;
      padding-bottom: 0; } }
  .image-text .image a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; }
  .image-text .image img {
    max-width: 282px;
    width: 80%; }
  .image-text .image:after {
    content: "+";
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 36px;
    width: 38px;
    height: 38px;
    background-color: #6BA500;
    border-radius: 50%;
    line-height: 35px;
    color: white;
    text-align: center;
    vertical-align: middle;
    pointer-events: none; }
  .image-text .image .title {
    font-size: 48px;
    text-align: center;
    line-height: 1;
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .image-text .image .byline {
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    color: #999999;
    font-size: 21px;
    letter-spacing: .4rem;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4;
    max-width: 100%; }

.image-text .text {
  width: auto; }
  @media screen and (min-width: 768px) {
    .image-text .text {
      width: 45%; } }

.accordion {
  margin: 75px 0 125px; }

.accordion li {
  margin-bottom: 28px; }

.accordion li.active {
  margin-bottom: 55px; }

.accordion li.active .heading + .accordion-content {
  display: block; }

.accordion li.active .symbol.on {
  display: block; }

.accordion li.active .symbol.off {
  display: none; }

.accordion li.active .icon {
  line-height: 30px; }

.accordion li p {
  margin-top: 38px; }

.accordion .heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.accordion .heading .symbol.on {
  display: none; }

.accordion .heading h4 {
  width: calc(100% - 20%);
  font-size: 22px;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    .accordion .heading h4 {
      font-size: 36px; } }

.accordion .heading + .accordion-content {
  display: none; }

.accordion .icon {
  font-size: 36px;
  width: 38px;
  height: 38px;
  text-align: center;
  border-radius: 50%;
  line-height: 35px;
  color: white; }

.number-list-number {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  padding-bottom: 8px; }
  @media screen and (min-width: 768px) {
    .number-list-number {
      width: 77px;
      height: 77px; } }

.quote-module {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 0 30px; }
  @media screen and (min-width: 1024px) {
    .quote-module {
      flex-direction: row; } }
  .quote-module .quote-image {
    width: 86%;
    position: relative;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-image {
        min-width: 315px;
        width: 315px;
        height: 480px;
        margin: 0 0; } }
    .quote-module .quote-image img {
      border-radius: 50%;
      margin: 0 auto;
      display: block; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-image img {
          height: 315px; } }
    .quote-module .quote-image .quote-control {
      position: absolute;
      right: 24px;
      bottom: 11px; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-image .quote-control {
          right: 0px;
          bottom: 40px; } }
      .quote-module .quote-image .quote-control .quote-play {
        color: white;
        background-color: #6BA500;
        width: 115px;
        height: 115px;
        border-radius: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
        cursor: pointer; }
        .quote-module .quote-image .quote-control .quote-play i {
          font-size: 40px; }
      .quote-module .quote-image .quote-control .quote-length {
        text-align: center;
        font-family: 'Alte Haas Grotesk', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        padding-top: 20px;
        letter-spacing: 5px;
        font-weight: 700; }
  .quote-module.audio .quote-image {
    padding-bottom: 90px; }
  .quote-module .quote-text {
    position: relative;
    margin: 0;
    display: block; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-text {
        margin: 0;
        width: 61%; } }
    .quote-module .quote-text p {
      color: #6BA500;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100;
      font-size: 25px;
      line-height: 1.2; }
      @media screen and (min-width: 1024px) {
        .quote-module .quote-text p {
          font-size: 40px; } }
  .quote-module .quote-name {
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
    margin: 40px 0;
    display: block;
    position: relative; }
    @media screen and (min-width: 1024px) {
      .quote-module .quote-name {
        text-align: left;
        font-size: 21px; } }

.row-cards {
  display: flex; }
  @media all and (max-width: 767px) {
    .row-cards {
      font-size: 9px;
      right: 14px;
      left: auto;
      position: relative; } }
  @media all and (max-width: 1023px) {
    .row-cards {
      font-size: 12px; } }
  .row-cards .card {
    padding: 4%; }
    @media all and (max-width: 767px) {
      .row-cards .card {
        margin: 2%; } }
    .row-cards .card:after {
      content: '';
      display: block;
      width: 3rem;
      right: -3rem;
      border: none;
      height: 1px;
      padding: 0;
      font-size: 0;
      background-color: #6BA500;
      position: absolute; }
      @media all and (max-width: 767px) {
        .row-cards .card:after {
          width: 17px;
          right: -17px; } }
    .row-cards .card:last-child:after {
      display: none; }
  .row-cards.three .card {
    width: 32%;
    min-width: 32%; }

.grid-cards h5, .grid-titles h5 {
  font-size: 1.5vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  max-width: 100%;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    .grid-cards h5, .grid-titles h5 {
      letter-spacing: 5px;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    .grid-cards h5, .grid-titles h5 {
      font-size: 21px; } }

.grid-cards .grid-card, .grid-titles .grid-card {
  border-width: 1px;
  border-style: solid;
  max-width: calc((100% - 28px) / 3); }
  @media screen and (min-width: 768px) {
    .grid-cards .grid-card, .grid-titles .grid-card {
      max-width: calc((100% - 84px) / 3); } }

.grid-cards.orange .grid-card, .grid-cards.orange .grid-title, .grid-titles.orange .grid-card, .grid-titles.orange .grid-title {
  margin: 0 7px; }
  @media screen and (min-width: 768px) {
    .grid-cards.orange .grid-card, .grid-cards.orange .grid-title, .grid-titles.orange .grid-card, .grid-titles.orange .grid-title {
      margin: 0 21px; } }

.grid-cards.orange .grid-card, .grid-titles.orange .grid-card {
  border-color: #F76D27;
  padding: 0px 8px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .grid-cards.orange .grid-card, .grid-titles.orange .grid-card {
      padding: 32px 32px; } }

.grid-cards.orange div.grid-card:first-child, .grid-titles.orange div.grid-card:first-child {
  margin-left: 0; }

.grid-cards.orange div.grid-card:last-child, .grid-titles.orange div.grid-card:last-child {
  margin-right: 0;
  background: #F76D27; }
  .grid-cards.orange div.grid-card:last-child h5, .grid-titles.orange div.grid-card:last-child h5 {
    color: white; }

.grid-cards.orange div.grid-card:not(:last-child):after, .grid-titles.orange div.grid-card:not(:last-child):after {
  content: '';
  background-color: transparent;
  background-image: url("/public/images/arrow-orange.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 36px;
  position: absolute;
  top: calc(50% - 7.5%);
  right: -22px;
  border: none;
  height: 15%;
  transform: rotate(-90deg);
  z-index: 5;
  background-size: 30%; }

@media screen and (min-width: 768px) {
  .grid-cards.orange div.grid-card:not(:last-child), .grid-titles.orange div.grid-card:not(:last-child) {
    right: -10px; } }

.grid-cards {
  margin-bottom: 42px; }

.grid-titles h5 {
  color: #F76D27; }

.screen-comp-modals {
  position: relative;
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  @media screen and (min-width: 960px) {
    .screen-comp-modals {
      height: 57vh;
      align-items: flex-end;
      justify-content: flex-start; } }
  .screen-comp-modals .comp-target {
    position: relative;
    z-index: 2;
    cursor: pointer; }
    .screen-comp-modals .comp-target:after {
      content: "+";
      position: absolute;
      top: -15px;
      right: 1px;
      font-size: 36px;
      width: 38px;
      height: 38px;
      background-color: #6BA500;
      border-radius: 50%;
      line-height: 35px;
      color: white; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target {
        margin: 20px 0; } }
    .screen-comp-modals .comp-target.comps-1:after {
      top: -10px;
      right: 0px; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target.comps-1 {
        width: 50%; } }
    .screen-comp-modals .comp-target.comps-2:after {
      top: -9px;
      right: 16px; }
    .screen-comp-modals .comp-target.comps-3:after {
      top: -16px;
      right: -5px; }
    .screen-comp-modals .comp-target.comps-5:after {
      top: -8px;
      right: -1px; }
    @media all and (max-width: 959px) {
      .screen-comp-modals .comp-target.comps-5 {
        width: 80%; } }
    @media screen and (min-width: 960px) {
      .screen-comp-modals .comp-target {
        position: absolute; }
        .screen-comp-modals .comp-target.comps-1 {
          width: 10%;
          z-index: 5;
          left: 23%;
          bottom: -52px; }
          .screen-comp-modals .comp-target.comps-1:after {
            left: 0;
            right: auto; }
        .screen-comp-modals .comp-target.comps-2 {
          width: 43%;
          z-index: 4;
          right: 1%;
          bottom: -12px; }
          .screen-comp-modals .comp-target.comps-2:after {
            left: 46px;
            right: auto; }
        .screen-comp-modals .comp-target.comps-3 {
          width: 49%;
          z-index: 3;
          left: 22%;
          bottom: -17px; }
          .screen-comp-modals .comp-target.comps-3:after {
            right: 13px; }
        .screen-comp-modals .comp-target.comps-5 {
          width: 26%;
          z-index: 3;
          left: 3%;
          bottom: -8px; } }

.modals.comps .modal .modal-image {
  align-items: flex-start; }
  .modals.comps .modal .modal-image img {
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.7); }
    @media screen and (min-width: 960px) {
      .modals.comps .modal .modal-image img.mobile-img {
        max-width: 26%; } }

.modals .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 100; }
  .modals .overlay.display {
    display: block;
    background-image: url("/public/images/Hash-Pattern.png");
    background-position: center;
    background-size: cover;
    opacity: .3;
    height: 100vh;
    width: 100vw; }

.modals .modal {
  z-index: 101;
  position: fixed;
  width: 90vw;
  height: 88vh;
  top: 9vh;
  bottom: 3vh;
  left: 5vw;
  right: 5vw;
  background-color: white;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px; }
  @media all and (min-width: 768px) {
    .modals .modal {
      top: 7.5vh;
      bottom: 7.5vh;
      left: 5.5vw;
      right: 7.5vw; } }
  .modals .modal.fluid {
    height: auto;
    max-height: 88vh;
    bottom: unset; }
    @media all and (min-width: 768px) {
      .modals .modal.fluid {
        bottom: unset;
        max-height: 85vh; } }
  .modals .modal.active {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .modals .modal .modal-image {
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modals .modal .close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #9013FE;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px; }
    @media all and (min-width: 768px) {
      .modals .modal .close {
        top: 50px;
        right: 50px;
        width: 75px;
        height: 75px; } }
    .modals .modal .close:before {
      content: "+";
      position: absolute;
      font-size: 35px;
      width: 35px;
      height: 35px;
      line-height: 32px;
      color: white;
      font-family: "clarendon-urw", serif;
      font-style: normal;
      font-weight: 100; }
      @media all and (min-width: 768px) {
        .modals .modal .close:before {
          top: -5px;
          right: -9px;
          font-size: 75px;
          width: 95px;
          height: 95px;
          line-height: 75px; } }
  .modals .modal.orange .close {
    background-color: #F76D27; }
  .modals .modal .black5 {
    margin-top: 10px; }
  .modals .modal .title {
    font-family: "clarendon-urw", serif;
    font-style: normal;
    font-weight: 100;
    color: #9013FE;
    position: relative;
    padding: 20px 0;
    font-size: 45px;
    left: 0;
    line-height: 1.2; }
    @media all and (min-width: 960px) {
      .modals .modal .title {
        font-size: 64px; } }
    @media all and (min-width: 1024px) {
      .modals .modal .title {
        left: -60px; } }
  .modals .modal .deliverables {
    margin-top: 50px;
    font-family: 'Alte Haas Grotesk', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px; }
  @media all and (min-width: 768px) {
    .modals .modal ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2; } }
  .modals .modal ul li {
    color: #9013FE;
    font-weight: 500;
    padding: 10px 0;
    line-height: 1.3;
    padding-right: 4%; }

.modals:not(.comps) .modal.active {
  padding: 10vh 20px; }

.taglines h3 {
  color: white !important; }

html {
  overflow: hidden; }
  @media all and (max-height: 859px) {
    html {
      overflow-y: scroll; } }

.loaded-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 1;
  pointer-events: none;
  z-index: 100;
  transition: opacity 5s ease; }
  @media screen and (min-width: 1024px) {
    .loaded-overlay {
      transition: opacity 15s ease; } }

body.loaded .loaded-overlay {
  opacity: 0; }

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: "clarendon-urw", serif;
  font-style: normal;
  font-weight: 100; }

.audio-controls {
  visibility: hidden;
  position: absolute; }

.body-bg {
  position: absolute;
  z-index: -4;
  width: 100%;
  min-height: 100vh;
  background-image: url("/public/images/fireplace.gif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 1024px) {
    .body-bg {
      display: none; } }
  .body-bg:before {
    content: '';
    background-color: black;
    opacity: .8;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -3; }

.main-menu {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-top: 0;
  overflow-x: hidden;
  position: absolute;
  pointer-events: none;
  transition: opacity 1s ease, width 1s ease, margin-top 1s ease; }
  .main-menu.hidden {
    opacity: 0; }
  .main-menu .lock-up {
    text-align: center;
    padding: 2rem 0 1.5rem;
    pointer-events: all;
    opacity: 1;
    pointer-events: none;
    transition: height .5s ease, opacity .15s ease, padding .5s ease, bottom .5s ease; }
    @media all and (max-width: 1023px) {
      .main-menu .lock-up.full-screen-hide {
        position: fixed;
        bottom: 2vh;
        width: 100vw; } }
    @media screen and (min-width: 1024px) {
      .main-menu .lock-up.full-screen-hide {
        width: 100%;
        opacity: 1; } }
    .main-menu .lock-up.full-screen-show {
      height: 0px;
      padding: 0;
      opacity: 0; }
    .main-menu .lock-up img {
      width: 40%;
      max-width: 250px;
      transition: width 1s ease; }
      @media screen and (min-width: 1024px) {
        .main-menu .lock-up img {
          width: 100%; } }
  .main-menu .main-menu-nav {
    text-align: center;
    width: 50%;
    margin: 0 auto; }
    .main-menu .main-menu-nav .home-logo {
      padding: 2rem 0 0 0;
      transition: padding .5s ease;
      cursor: pointer; }
      @media screen and (min-width: 768px) {
        .main-menu .main-menu-nav .home-logo {
          padding: 4rem 0 0 0;
          width: 100%; } }
      .main-menu .main-menu-nav .home-logo img {
        width: 60%;
        max-width: 400px;
        transition: width 1s ease; }
        @media screen and (min-width: 1024px) {
          .main-menu .main-menu-nav .home-logo img {
            width: 40%; } }
    .main-menu .main-menu-nav .audio-video-chooser-container {
      background-color: rgba(0, 0, 0, 0.5);
      width: 60vw;
      top: 21%;
      position: fixed;
      left: -100vw;
      padding: 1rem 0 2rem;
      pointer-events: none;
      transition: left .5s ease .25s; }
      @media screen and (min-width: 768px) {
        .main-menu .main-menu-nav .audio-video-chooser-container {
          top: 28%; } }
      @media screen and (min-width: 1024px) {
        .main-menu .main-menu-nav .audio-video-chooser-container {
          position: absolute; } }
      .main-menu .main-menu-nav .audio-video-chooser-container .av-button {
        color: white;
        font-weight: bold;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 30px;
        right: -40px;
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        line-height: 38px;
        pointer-events: all; }
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon {
          margin: 0 auto;
          width: 25px;
          margin-top: 11px; }
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon:after,
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon:before,
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon div {
          background-color: white;
          border-radius: 3px;
          content: '';
          display: block;
          height: 3px;
          margin: 4px 0;
          transition: all .2s ease-in-out; }
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon:before {
          transform: translateY(8px) rotate(135deg); }
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon:after {
          transform: translateY(-6px) rotate(-135deg); }
        .main-menu .main-menu-nav .audio-video-chooser-container .av-button .nav-icon div {
          transform: scale(0); }
        @media screen and (min-width: 1024px) {
          .main-menu .main-menu-nav .audio-video-chooser-container .av-button {
            display: none; } }
      @media screen and (min-width: 1024px) {
        .main-menu .main-menu-nav .audio-video-chooser-container {
          background-color: transparent;
          width: auto;
          position: relative;
          left: 0;
          padding: 0;
          transition: left .5s ease .25s; } }
      .main-menu .main-menu-nav .audio-video-chooser-container .player-view-switch-menu {
        height: 0px;
        pointer-events: none;
        margin-left: -900rem;
        opacity: 0;
        transition: height .25s ease, margin-left .5s ease 0s, opacity .25s ease 0s; }
        .main-menu .main-menu-nav .audio-video-chooser-container .player-view-switch-menu p {
          font-size: 1rem;
          margin: 1rem 0 .5rem; }
        .main-menu .main-menu-nav .audio-video-chooser-container .player-view-switch-menu button {
          padding: .4rem .5rem;
          border-radius: 0;
          color: #00ADCD;
          font-weight: bold;
          font-size: .8rem;
          letter-spacing: .04rem;
          cursor: pointer;
          border: none; }
          .main-menu .main-menu-nav .audio-video-chooser-container .player-view-switch-menu button:focus {
            outline: none;
            background-color: #00ADCD;
            color: white; }
          .main-menu .main-menu-nav .audio-video-chooser-container .player-view-switch-menu button:hover {
            background-color: #00ADCD;
            color: white; }
      .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field {
        text-align: left;
        padding-left: calc(24% + 5px); }
        @media screen and (min-width: 1024px) {
          .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field {
            padding-left: calc(24px + 4px); } }
        .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field input {
          margin-right: 14px;
          position: relative; }
          .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field input:after {
            content: '';
            content: '';
            display: block;
            width: 26px;
            height: 26px;
            background: white;
            background: radial-gradient(circle, white 0%, #65b7e6 100%);
            -webkit-box-shadow: inset 0px 0px 0px 6px white;
            -moz-box-shadow: inset 0px 0px 0px 6px white;
            box-shadow: inset 0px 0px 0px 6px white;
            border-radius: 100%;
            z-index: 2;
            position: absolute;
            top: -7px;
            left: -7px; }
            @media screen and (min-width: 1024px) {
              .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field input:after {
                top: -6px;
                left: -7px; } }
          .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field input:checked:after {
            background: #65B7E6;
            background: #65B7E6; }
        .main-menu .main-menu-nav .audio-video-chooser-container .form .regular-field label {
          color: white;
          font-size: .9rem; }
      .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list {
        height: 0px;
        pointer-events: none;
        margin-left: -900rem;
        top: 40%;
        opacity: 0;
        transition: height .25s ease , margin-left .5s ease 0s, opacity .5s ease 0s; }
        .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list p {
          font-size: 1rem;
          margin: 1rem 0 .5rem; }
        .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list ul {
          text-align: left;
          padding-left: 24%; }
          @media screen and (min-width: 1024px) {
            .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list ul {
              padding-left: 24px; } }
          .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list ul li a {
            color: white;
            font-size: .9rem;
            cursor: pointer; }
            .main-menu .main-menu-nav .audio-video-chooser-container .player-view-music-list ul li a i {
              margin-right: 10px;
              font-size: 10px;
              background-color: white;
              border-radius: 100%;
              width: 26px;
              height: 26px;
              color: #00ADCD;
              display: inline-flex;
              align-items: center;
              justify-content: center; }
  .main-menu .menu-text {
    text-align: center;
    padding: 4vh 0 3vh;
    transition: opacity .5s ease .5s, max-height .25s ease 0s;
    width: 88%;
    margin: 0 auto; }
    .main-menu .menu-text p {
      font-size: 4vw; }
      @media all and (max-width: 767px) {
        .main-menu .menu-text p {
          margin-bottom: .4rem; } }
    @media screen and (min-width: 768px) {
      .main-menu .menu-text {
        width: 78%;
        padding: 3% 0% 3%; } }
    @media screen and (min-width: 1024px) {
      .main-menu .menu-text {
        width: 73%;
        padding: 0% 0% 3%; }
        .main-menu .menu-text p {
          font-size: 21px; } }
    @media screen and (min-width: 1280px) {
      .main-menu .menu-text {
        width: 58%; } }
    .main-menu .menu-text.hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity .5s ease 0s; }

.main-menu.full-screen {
  width: 100%;
  margin-top: 0%;
  pointer-events: all;
  height: 100vh;
  overflow: hidden; }
  @media screen and (min-width: 1024px) {
    .main-menu.full-screen {
      width: 20%;
      height: auto;
      min-width: 310px; }
      .main-menu.full-screen .lock-up.full-screen-show {
        opacity: 1;
        padding: 4rem 0 0 0;
        height: auto;
        transition: height .5s ease, opacity .5s ease 1s, padding .5s ease; }
        .main-menu.full-screen .lock-up.full-screen-show img {
          width: 100%; }
      .main-menu.full-screen .home-logo {
        padding: 4rem 0 1rem; }
        .main-menu.full-screen .home-logo img {
          width: 100%; } }
  .main-menu.full-screen .menu-text.hidden {
    position: absolute;
    display: none; }
  .main-menu.full-screen .slider-buttons.hidden {
    position: absolute;
    display: none; }
  .main-menu.full-screen .lock-up.full-screen-hide {
    opacity: 0;
    display: none; }
    @media all and (max-width: 1023px) {
      .main-menu.full-screen .lock-up.full-screen-hide {
        position: fixed;
        bottom: 4vh; } }
  .main-menu.full-screen .audio-video-chooser-container {
    pointer-events: all;
    left: 0; }
    .main-menu.full-screen .audio-video-chooser-container.open {
      left: 0; }
      @media all and (max-width: 768px) {
        .main-menu.full-screen .audio-video-chooser-container.open {
          left: -60vw; } }
      .main-menu.full-screen .audio-video-chooser-container.open .av-button .nav-icon:before {
        transform: translateY(0px) rotate(0deg); }
      .main-menu.full-screen .audio-video-chooser-container.open .av-button .nav-icon:after {
        transform: translateY(0px) rotate(0deg); }
      .main-menu.full-screen .audio-video-chooser-container.open .av-button .nav-icon div {
        transform: scale(1); }
    .main-menu.full-screen .audio-video-chooser-container .player-view-switch-menu {
      pointer-events: all;
      height: 118px;
      opacity: 1;
      margin-left: 0;
      transition: height .5s ease .25s, margin-left .5s ease .5s, opacity .5s ease .5s; }
      @media screen and (min-width: 1024px) {
        .main-menu.full-screen .audio-video-chooser-container .player-view-switch-menu {
          margin-left: 0rem; } }
    .main-menu.full-screen .audio-video-chooser-container .player-view-music-list {
      pointer-events: all;
      height: 210px;
      opacity: 1;
      margin-left: 0;
      transition: height .5s ease .25s, margin-left .5s ease .5s, opacity .5s ease .5s; }
      @media screen and (min-width: 1024px) {
        .main-menu.full-screen .audio-video-chooser-container .player-view-music-list {
          margin-left: 0rem; } }
      .main-menu.full-screen .audio-video-chooser-container .player-view-music-list ul li a {
        color: white;
        text-decoration: none; }

@media all and (max-device-width: 850px) and (max-device-height: 400px) and (orientation: landscape) {
  .main-menu .lock-up {
    padding: 1rem 0; }
    .main-menu .lock-up img {
      width: 130px; }
  .main-menu .main-menu-nav .home-logo img {
    width: 114px; }
  .main-menu .menu-text {
    padding: 1% 0% 2%; }
    .main-menu .menu-text p {
      margin-bottom: .5rem; }
  .main-menu .main-menu-nav .audio-video-chooser-container {
    width: 32vw;
    top: 0;
    bottom: 0; }
  .main-menu.full-screen .audio-video-chooser-container {
    left: -32vw;
    pointer-events: all; } }

.steps-visuals {
  display: none;
  position: relative;
  height: 46vw;
  pointer-events: none; }
  @media screen and (min-width: 768px) {
    .steps-visuals {
      height: 35vw; } }
  .steps-visuals .step {
    width: 90vw;
    margin: 0 auto;
    padding: 1rem 0;
    position: absolute; }
    @media screen and (min-width: 768px) {
      .steps-visuals .step {
        width: 78vw; } }
    .steps-visuals .step img {
      width: 40%; }
    .steps-visuals .step p {
      margin-bottom: 0rem; }
  .steps-visuals .step.one {
    animation: fade 8s infinite;
    -webkit-animation: fade 8s infinite; }
  .steps-visuals .step.two {
    animation: fade2 8s infinite;
    -webkit-animation: fade2 8s infinite; }
    .steps-visuals .step.two img {
      width: 30%; }
  @media all and (max-device-width: 768px) and (max-device-height: 1024px) and (orientation: portrait) {
    .steps-visuals {
      display: block; } }

@keyframes fade {
  0% {
    opacity: 1; }
  33.333% {
    opacity: 0; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade2 {
  0% {
    opacity: 0; }
  33.333% {
    opacity: 1; }
  66.666% {
    opacity: 0; }
  100% {
    opacity: 0; } }

#home-slider {
  display: none; }
  @media screen and (min-width: 1024px) {
    #home-slider {
      display: block;
      z-index: -1;
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      transition: all 1s ease; }
      #home-slider.hidden {
        opacity: 0;
        z-index: -2; }
      #home-slider .holiday-slider {
        position: relative;
        width: 100%;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
        overflow: hidden; }
      #home-slider .holiday-slider:before {
        content: '';
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        z-index: 2; }
      #home-slider .beer-slider {
        display: inline-block;
        position: relative;
        overflow: hidden; }
        #home-slider .beer-slider *, #home-slider .beer-slider:before, #home-slider .beer-slider:after,
        #home-slider .beer-slider *:before,
        #home-slider .beer-slider *:after {
          box-sizing: border-box; }
        #home-slider .beer-slider img, #home-slider .beer-slider svg {
          vertical-align: bottom; }
        #home-slider .beer-slider > img {
          max-width: 100%;
          height: auto; }
      #home-slider .beer-reveal {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 50%;
        overflow: hidden;
        z-index: 1;
        opacity: 1;
        width: 50%;
        transition: opacity 0.35s; }
        #home-slider .beer-reveal > :first-child {
          width: 200%;
          max-width: none; }
        #home-slider .beer-reveal > img:first-child {
          height: auto; }
      #home-slider .beer-range {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: 0;
        left: -1px;
        width: calc(100% + 2px);
        cursor: pointer;
        -webkit-appearance: slider-horizontal !important;
        -moz-appearance: none;
        opacity: 0;
        -ms-touch-action: auto;
        touch-action: auto; }
        #home-slider .beer-range::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 300vh; }
        #home-slider .beer-range::-moz-range-thumb {
          -webkit-appearance: none;
          height: 300vh; }
        #home-slider .beer-range::-ms-tooltip {
          display: none; }
      #home-slider .beer-handle {
        position: absolute;
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        top: 50vh;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: #000;
        background: rgba(101, 183, 230, 0.5);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0);
        transition: background 0.3s, box-shadow 0.3s, opacity 0.5s 0.25s; }
        #home-slider .beer-handle:before, #home-slider .beer-handle:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          top: 50%;
          border-top: solid 2px;
          border-left: solid 2px;
          transform-origin: 0 0; }
        #home-slider .beer-handle:before {
          left: 10px;
          transform: rotate(-45deg); }
        #home-slider .beer-handle:after {
          right: 0;
          transform: rotate(135deg); }
      #home-slider .beer-range:focus ~ .beer-handle {
        background: rgba(101, 183, 230, 0.85);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); }
      #home-slider .beer-ready .beer-reveal, #home-slider .beer-ready .beer-handle {
        opacity: 1; } }

.slider-buttons {
  width: 100%;
  text-align: center;
  z-index: 2;
  opacity: 1;
  transition: opacity .25s ease .5s; }
  .slider-buttons .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
  .slider-buttons .title {
    margin-bottom: 1rem; }
  .slider-buttons button {
    pointer-events: all;
    margin: 0 4%;
    padding: .7rem 1rem;
    border-radius: 0;
    color: white;
    font-size: 1.1rem;
    background-color: #65B7E6;
    outline: none;
    border: none; }
    @media screen and (min-width: 1024px) {
      .slider-buttons button {
        padding: .7rem 5rem; } }
    .slider-buttons button:focus {
      outline: none; }
  .slider-buttons.hidden {
    opacity: 0;
    transition: opacity .25s ease 0s;
    pointer-events: none; }

.vimeo-container {
  min-width: 100%;
  min-height: 100vh; }
  .vimeo-container iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.player {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%; }

#cookies-player, #fire-player {
  opacity: 1;
  pointer-events: all;
  transition: opacity 1s ease; }
  #cookies-player.hidden, #fire-player.hidden {
    opacity: 0;
    pointer-events: none; }

.player-instructions {
  position: fixed;
  color: white;
  bottom: 6%;
  z-index: 2;
  width: 100%;
  text-align: center;
  text-shadow: 1px 1px 4px black;
  font-size: 1rem; }
